<template>
    <main>
        <div class="card-header bg-light "><span><i class="fa fa-list"> </i> Eventos de la Violencia Sexual</span> </div>
            <div class="card-body">
            <div class="row mt-2">
                <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="(item) in eventos_violencia" :key="item.id">
                    <div class="list-group-item">
                        <div class=" ">
                            <span class="font-weight-bolder">{{ item.evento_sexual.descripcion }}</span>
                            <p>{{item.valor}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import eventoViolenciaSexualUsuarioService from '../../../../services/eventoViolenciaSexualUsuarioService';
export default {
    props: ['embarazo_actual', 'usuario', 'idHistoria', 'finalizada', 'modo'],
    data() {
        return {
            eventos_violencia: []
        }
    },
    
    async created() {
        const response = await eventoViolenciaSexualUsuarioService.index(
            this.idHistoria
        ); 

        this.eventos_violencia=response.data;

    }

}
</script>